import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Styling from './Styling/index';
import Detail from './Styling/Myportofolio/detail';
import Sass from './Styling/Myportofolio/scss/index';
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Styling />} />
          <Route path="/detail" element={<Detail />} />
          <Route path="/card" element={<Sass />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
