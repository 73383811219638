import React from 'react';
class Footer extends React.Component {
  render() {
    return (
      <footer className="text-center bg text-white p-3">
        <span>
          Created By <a href="www.youtube.com">Hamdhany</a> &copy; 2022 All Rights Reserved.
        </span>
      </footer>
    );
  }
}
export default Footer;
