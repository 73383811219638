import React from 'react';
import './Style/Portofolio.css';
import { Link } from 'react-router-dom';
class Portofolio extends React.Component {
  render() {
    return (
      <section id="portofolio">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title text-center">Portofolio</div>
              <div className="row pb-5">
                <div className="col-md-12">
                  <div className="container">
                    <div className="gallery">
                      <div className="gallery-item">
                        <img className="gallery-image" src="https://asset.hamdhany12.repl.co/assets/182.jpg" alt="" />
                        <div className="gallery-content">
                          <ul className="social-link">
                            <li>
                              <Link to="/detail">
                                <div className="icon-9" id="icon">
                                  <i className="fa-solid fa-eye"></i>
                                </div>
                              </Link>
                            </li>
                            <li>
                              <a href="http://shofood.alhamdhany.my.id/" target="_blank" rel="noopener noreferrer">
                                <div className="icon-10" id="icon">
                                  <i className="fa-solid fa-circle-info"></i>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="https://github.com/alhamdhany1310/StudyCase" target="_blank" rel="noopener noreferrer">
                                <div className="icon-7" id="icon">
                                  <i className="fa-brands fa-github"></i>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="gallery-item">
                        <img className="gallery-image" src="https://asset.hamdhany12.repl.co/assets/1.jpg" alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night" />
                        <div className="gallery-content">
                          <ul className="social-link">
                            <li>
                              <a href="https://mls.co.id/" target="_blank" rel="noopener noreferrer">
                                <div className="icon-10" id="icon">
                                  <i className="fa-solid fa-circle-info"></i>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="https://github.com/alhamdhany1310" target="_blank" rel="noopener noreferrer">
                                <div className="icon-7" id="icon">
                                  <i className="fa-brands fa-github"></i>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="gallery-item">
                        <img className="gallery-image" src="https://asset.hamdhany12.repl.co/assets/2.jpg" alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night" />
                        <div className="gallery-content">
                          <ul className="social-link">
                            <li>
                              <a href="https://lombokit.com/" target="_blank" rel="noopener noreferrer">
                                <div className="icon-10" id="icon">
                                  <i className="fa-solid fa-circle-info"></i>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a href="https://github.com/alhamdhany1310" target="_blank" rel="noopener noreferrer">
                                <div className="icon-7" id="icon">
                                  <i className="fa-brands fa-github"></i>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Portofolio;
