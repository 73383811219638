import React from 'react';
import './Style/Skill.css';
import oke from './image/oke.png';
import Sass from './scss';
import Backend from './scss/Backend';
import Design from './scss/Design';
class Skill extends React.Component {
  render() {
    return (
      <section id="skill">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title">My Skill</h2>
              <div className="row pb-5">
                <div className="col-md-6">
                  <img className="skill-img" src={oke} alt="" />
                </div>
                <div className="col-md-6">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                          <h5 className="text-light">
                            <i className="fa-solid fa-code text-light"> </i> Frond End
                          </h5>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div className="accordion-body">
                          <Sass />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                          <h5 className="text-light">
                            <i className="fa-solid fa-server"> </i> Back end
                          </h5>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                          <Backend />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                          <h5 className="text-light">
                            <i className="fa-solid fa-object-group"> </i> Design
                          </h5>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                        <div className="accordion-body">
                          <Design />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Skill;
