import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './Style/Navbar.css';
const Navbar = () => {
  const [navbar, setNavbar] = useState(false);

  const ubahBg = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener('scroll', ubahBg);

  return (
    <nav className={navbar ? 'navbar sticky navbar-expand-lg shadow-sm bg-body fixed-top' : 'navbar navbar-dark navbar-expand-lg fixed-top'}>
      <div className="container">
        <Link to="home" className="navbar-brand">
          <span>Al Hamdhany</span>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon">
            <i className="fas fa-bars"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link to="home" spy={true} smooth={true} offset={-65} duration={200} className="nav-link" aria-current="page">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="bio" spy={true} smooth={true} offset={-65} duration={200}>
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="skill" spy={true} smooth={true} offset={-65} duration={200}>
                Skill
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="portofolio" spy={true} smooth={true} offset={-65} duration={200}>
                Portofolio
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="contact" spy={true} smooth={true} offset={-65} duration={200}>
                Contact Me
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
// class Navbar extends React.Component {
//   render() {

// }
export default Navbar;
