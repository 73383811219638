import React from 'react';
import './Style.scss';
export default class Sass extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-4">
          <div className="card-bhs">
            <span className="badge">1</span>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/174/174854.png" alt="" />
            </div>
            <div className="text px-3 p-0">
              <span className="text-light p-1">Intermediate</span>
              <p>HTML</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-bhs">
            <span className="badge">2</span>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/732/732190.png" alt="" />
            </div>
            <div className="text px-3 p-0">
              <span className="text-light p-1">Intermediate</span>
              <p>Css</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-bhs">
            <span className="badge">3</span>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/5968/5968672.png" alt="" />
            </div>
            <div className="text px-3 p-0">
              <span className="text-light p-1">Intermediate</span>
              <p>Bootstrap</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-bhs">
            <span className="badge">4</span>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/4726/4726005.png" alt="" />
            </div>
            <div className="text px-3 p-0">
              <span className="text-light p-1">Intermediate</span>
              <p>Javascript</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-bhs">
            <span className="badge">5</span>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/753/753244.png" alt="" />
            </div>
            <div className="text px-3 p-0">
              <span className="text-light p-1">Intermediate</span>
              <p>ReactJs</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-bhs">
            <span className="badge">6</span>
            <div className="icon">
              <img src="https://cdn-icons-png.flaticon.com/512/1183/1183673.png" alt="" />
            </div>
            <div className="text px-3 p-0">
              <span className="text-light p-1">Basic</span>
              <p>VueJs</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
