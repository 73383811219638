import React from 'react';
import './Style.scss';

const Backend = () => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card-bhs">
          <span className="badge">1</span>
          <div className="icon">
            <img src="https://cdn-icons-png.flaticon.com/512/919/919825.png" alt="" />
          </div>
          <div className="text px-3 p-0">
            <span className="text-light p-1">Basic</span>
            <p>NodeJs</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card-bhs">
          <span className="badge">2</span>
          <div className="icon bg-white">
            <img src="https://ih1.redbubble.net/image.438908244.6144/st,small,507x507-pad,600x600,f8f8f8.u2.jpg" alt="" />
          </div>
          <div className="text px-3 p-0">
            <span className="text-light p-1">Intermediate</span>
            <p>ExpressJs</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card-bhs">
          <span className="badge">3</span>
          <div className="icon">
            <img src="https://cdn-icons-png.flaticon.com/512/919/919836.png" alt="" />
          </div>
          <div className="text px-3 p-0">
            <span className="text-light p-1">Basic</span>
            <p>MySql</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card-bhs">
          <span className="badge">4</span>
          <div className="icon">
            <img src="https://dwglogo.com/wp-content/uploads/2017/12/MongoDB_logo_01-218x150.png" alt="" />
          </div>
          <div className="text px-3 p-0">
            <span className="text-light p-1">Intermediate</span>
            <p>MongoDB</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
