import React from 'react';
import { Link } from 'react-router-dom';
import './Style/detail.css';

const Detail = () => {
  return (
    <div>
      <nav className="navbar bg-light fixed-top">
        <div className="container">
          <Link className="navbar-brand mb-0 h1 text-primary" to="/">
            Al Hamdhany
          </Link>
        </div>
      </nav>

      <div className="mt-5 p-md-5 mb-4 text-white bg1">
        <div className="container">
          <div className="col-md-7 pt-5">
            <h1 className="display-5 bg-warning rounded shadow-sm px-3">Website E-commerce Shofood</h1>
            <p className="lead text-light mt-4 bg-primary px-5 rounded">MongoDB || Express JS || React JS || Node JS</p>
          </div>
        </div>
      </div>

      <main className="container">
        <div className="row g-5">
          <div className="col-md-8">
            <article className="blog-post">
              <h2 className="blog-post-title">Tentang Website E-commerce Shofood</h2>
              <p className="blog-post-meta">
                October 5, 2022 by <a href="wwwwww">Al Hamdhany</a>
              </p>

              <p>
                Website e-commerce ini dibuat dengan menerapkan teknologi MERN yaitu React JS untuk frontend, redux untuk state management, Node JS dan Express JS untuk backend dan MongoDB sebagai database. Hak akses yang diterapkan dalam
                website e-commerce ini dibagi ke dalam 3 role yaitu sebagai admin, user dan guest. Sedangkan guest memiliki hak akses yang sangat terbatas karena ada beberapa fitur website yang hanya bisa diakses. jika sudah login (role
                user). Adapun fitur-fitur di dalam website e-commerce ini akan dijelaskan secara detail sebagai berikut :
              </p>
              <hr />
              <h2>Beranda</h2>
              <p>Halaman Beranda</p>
              <p>
                Halaman Beranda ini bisa diakses oleh semua role baik admin, user maupun guest. Pada bagian atas atau header halaman beranda terdapat input search yang dapat di manfaatkan untuk mencari product yang di inginkan selain itu
                terdapat login dan daftar yang bisa di manfaatkan untuk berpindah ke halaman daftar dan login dan terdapat icon cart/keranjang yang ketika di kelik akan beralih ke halaman keranjang namun apabila belum login sebagai user
                akan di alihkan ke halaman login dan di bagian body atau bawah header terdapat Category, tags dan jenis product.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/2.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/5.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
              <h2>Halaman Daftar</h2>
              <p>Pada halaman Register diperlukan data berupa nama, email, dan password yang secara default akan terdaftar sebagai role user.</p>
              <div className="row">
                <div className="col-md-12">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/4.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
              <h2>Halaman Login</h2>
              <p>
                Ketika melakukan login, user harus memasukkan email dan password yang sudah diaftarkan sebelumnya. Setelah login berhasil, maka website e-commerce dapat diakses sebagai role user dan bisa melakukan pemesanan product yang di
                inginkan.
              </p>
              <div className="row">
                <div className="col-md-12">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/3.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
              <h2>Halaman account</h2>
              <p>
                Pada halaman account memuat informasi tentang pemilik akun atau user di halaman ini juga terdapat side bar yang berisi My Account yang dapat menampilkan pemilik akun, Address Book melihat, menambah, mengedit dan menghapus
                alamat selajutnya My Orders menampilkan informasi atau riwayat orders yang pernah di lakukan dan yang terhir Keluar, yang apabila di kelik user akan logout atau keluar dari akun.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/11.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/12.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/13.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
              <h2>Halaman Cart</h2>
              <p>
                Halaman Cart memuat informasi detail produk, fitur penambahan dan pengurangan jumlah produk, total harga per produk dan total harga keseluruhan produk di dalam cart. Selain itu, terdapat fitur remove product dari cart dan
                button Proceed to Pay untuk melanjutkan ke tahap confirm order.
              </p>
              <div className="row">
                <div className="col-md-12">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/6.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
              <h2>Halaman checkout</h2>
              <p>
                Setelah mengklik Proceed to Pay pada halaman cart user akan di alihkan ke halaman checkout, pada halaman ini akan menampilakan pilihan alamat user bisa memilih alamat pengiriman yang di inginkan namun apabila user belum
                memiliki alamat pengiriman maka user bisa mengklik tambah alamat dan di halaman ini terdapat dua button yakni button batal dan button berikutnya apabila mengklik button batal makan akan di arahkan kembali menuju halaman cart
                dan apabila mengklik button berikutnya akan di alihkan menuju halaman konfirmasi, pada halaman konfirmasi terdapat detail alamat pengiriman, sub total harga, biaya ongkir dan total harga yang harus di bayarkan. di halaman
                ini pula terdapat dua button yakni button kembali apabila di kelik akan di arahkan ke halaman pilih alamat dan button order untuk mengkonfirmasi apakah data pemesanan.
              </p>
              <div className="row">
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/7.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
                <div className="col-md-6">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/8.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
              <h2>Halaman invoice</h2>
              <p>
                halaman terahir setelah melakukan order product yakni halaman invoice, pada halaman ini memuat informasi pesanan yang sudah dilakukan seperti statu pembayaran, order id, total pembayaran, akun yang melakukan order dan metode
                pembayaran. selain itu terdapta dua button yakni button halaman depan yang berfungsi akan mengalihkan user ke halaman dashbord dan button akun akan mengalihkan user ke halaman account.
              </p>
              <div className="row">
                <div className="col-md-12">
                  <figure className="figure">
                    <img src="https://asset.hamdhany12.repl.co/detail/9.png" className="figure-img img-fluid rounded" alt="..." />
                  </figure>
                </div>
              </div>
            </article>
          </div>

          <div className="col-md-4">
            <div className="position-sticky ">
              <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">About</h4>
                <span className="mb-0 text-dark">Website ini memiliki tampilan mirip serperti Shopee, di sebabkan developer atau pengembang website ini terinspirasi dari shopee salah satu E-Commerce terbesar di indonesia.</span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Detail;
