// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm('mbjbywrw');
  if (state.succeeded) {
    return (
      <div className="bg-primary rounded px-2 p-2" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex">
          <div className="fw-100">your message has been sent...!</div>
        </div>
      </div>
    );
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <div className="mb-3">
            <input type="text" name="full_name" className="form-control" id="full_name" aria-describedby="namaHelp" placeholder="Nama" />
            <ValidationError prefix="Name" field="full_name" errors={state.errors} />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="mb-3">
            <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
            <ValidationError prefix="Email" field="email" errors={state.errors} />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <input type="text" name="subject" className="form-control" id="subject" placeholder="Subject" />
        <ValidationError prefix="Message" field="subject" errors={state.errors} />
      </div>
      <div className="mb-3">
        <textarea className="form-control" name="message" id="message" rows="3" placeholder="Your Message"></textarea>
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>
      <button type="submit" className="btn btn-primary btn-lg" disabled={state.submitting}>
        Kirim
      </button>
    </form>
  );
}
function Email() {
  return <ContactForm />;
}
export default Email;
