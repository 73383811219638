import React from 'react';

const Design = () => {
  return (
    <div className="row">
      <div className="col-md-4">
        <div className="card-bhs">
          <span className="badge">1</span>
          <div className="icon bg-white">
            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968705.png" alt="" />
          </div>
          <div className="text px-3 p-0">
            <span className="text-light p-1">Basic</span>
            <p>Figma</p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card-bhs">
          <span className="badge">2</span>
          <div className="icon">
            <img src="https://cdn-icons-png.flaticon.com/512/5968/5968520.png" alt="" />
          </div>
          <div className="text px-3 p-0">
            <span className="text-light p-1">Intermediate</span>
            <p>Photoshop</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Design;
