import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import './Style/About.css';
import img from './image/img.jpg';
import cv from './image/Cv_Maspuk_Al_Hamdani.pdf';
const About = () => {
  const { text } = useTypewriter({
    words: ['Junior Web Developer', 'MERN Stack Dev', 'Frond End Dev', 'Youtuber'],
    loop: 0,
  });
  return (
    <section id="bio">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title text-center">About me</h2>
            <div className="row pb-5">
              <div className="col-md-6">
                <h4 className="pt-5">
                  I'm Hamdhany and I'm a <span>{text}</span>
                  <Cursor cursorStyle="|" />
                </h4>
                <p>
                  Saya adalah seorang junior web developer. saya mulai belajar tentang progarmming sejak 2021. Saat ini saya sudah memiliki pengalaman membuat beberapa aplikasi dan saya pernah juara dalam lomba Web Design. Saya juga belajar
                  mengenai MERN, (MongoDB, ExpressJs, ReactJS dan NodeJs) selama 7 bulan di
                  <a href="https://eduwork.id/" target="_blank" className="text-decoration-none" rel="noopener noreferrer">
                    {' '}
                    Eduwork.id
                  </a>
                </p>
                <a href={cv} download={img} className="btn btn-primary btn-lg" target="_blank" rel="noopener noreferrer">
                  Download CV
                </a>
              </div>
              <div className="col-md-6">
                <div className="cardcontainer">
                  <div className="card">
                    {' '}
                    <img src={img} className="about-img" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default About;
